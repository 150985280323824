import React, { useState } from "react";

import { Dimension } from "./context/dimension";
import { HowToUse } from "./modules";

type TypeInternationalization = "enUS" | "es" | "ptBR";

const Root: React.FunctionComponent = () => {
  const [width, setWindowWidth] = useState(window.outerWidth);
  const internationalization = localStorage.getItem(
    "@conecta:internationalization"
  ) as TypeInternationalization;
  return (
    <Dimension.Provider value={[width, setWindowWidth]}>
      <HowToUse
        internationalization={
          internationalization?.length > 0 ? internationalization : "ptBR"
        }
      />
    </Dimension.Provider>
  );
};

export default Root;

import React from "react";
import { color_neutral_2, color_neutral_4, Typography } from "zera";

import { Dimension } from "../../context/dimension";
import { ItemIcon } from "./Components";
import { ItemContainer, ItemText, ItemTitle } from "./style";

export interface IItemProps {
  title: string;
  content: string;
  icon: string;
  lastItem?: boolean;
}

export const Item: React.FunctionComponent<IItemProps> = ({
  title,
  content,
  icon,
  lastItem = false,
}) => {
  const [width, setWindowWidth] = React.useContext(Dimension);
  return (
    <ItemContainer lastItem={lastItem}>
      <ItemIcon showLine={!lastItem} icon={icon} />
      <ItemText>
        <ItemTitle>
          <Typography
            text={title}
            fontFamily="Work Sans"
            fontWeight={600}
            lineHeight="1.5"
            fontSize={width >= 769 ? "6" : "5"}
            color={color_neutral_2}
          />
        </ItemTitle>
        <Typography
          text={content}
          fontFamily="Inter"
          fontWeight={400}
          fontSize="3"
          lineHeight="1.5"
          color={color_neutral_4}
        />
      </ItemText>
    </ItemContainer>
  );
};

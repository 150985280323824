import { enUS, es, ptBR } from "../../i18n";

type TypeInternationalization = "enUS" | "es" | "ptBR";

const showTexts = (internationalization: TypeInternationalization) => {
  const i18n = { enUS, es, ptBR };

  return {
    titleSection: i18n[internationalization].titleSection,
    image: i18n[internationalization].image,
    items: i18n[internationalization].items,
  };
};

export default showTexts;

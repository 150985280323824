import styled from "styled-components";

export const HowToUseContainer = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 96px 0 96px 0;
`;

export const HowToUseWrapper = styled.div`
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
`;

export const HowToUseTitle = styled.div`
  margin-bottom: 40px;
  @media (min-width: 769px) {
    margin-bottom: 80px;
  }
`;

export const HowToUseContent = styled.div`
  padding: 24px;
  @media (min-width: 769px) {
    max-width: 570px;
    margin-right: 106px;
  }
  width: 100%;
`;

export const HowToUseImageSection = styled.div`
  margin-top: 150px;
  @media (max-width: 768px) {
    padding: 20.5px 20.5px !important;
    margin-top: 40px;
    max-width: 420px;
  }
`;

import styled from "styled-components";

interface ItemContainerProps {
  lastItem?: boolean;
}

export const ItemContainer = styled.div<ItemContainerProps>`
  display: flex;
  @media (max-width: 768px) {
    ${(props) => (!props?.lastItem ? "margin-bottom: 2rem" : "")}
  }
`;

export const ItemText = styled.div`
  margin-left: 40px;
  @media (min-width: 769px) {
    min-width: 406px;
  }
`;

export const ItemTitle = styled.div`
  margin-bottom: 16px;
`;

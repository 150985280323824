import styled from "styled-components";
import { color_neutral_8 } from "zera";

export const ItemIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ItemIconLine = styled.div`
  height: 56px;
  margin: 20px 0 20px 0;
  border-right: 1px solid ${color_neutral_8};
`;

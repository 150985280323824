import React from "react";
import { color_neutral_1, Image, Typography } from "zera";

import { Dimension } from "../../context/dimension";
import { showTexts } from "../../helpers";
import { Item } from "../Item/Item";
import {
  HowToUseContainer,
  HowToUseContent,
  HowToUseImageSection,
  HowToUseTitle,
  HowToUseWrapper,
} from "./style";

export interface IHowToUseProps {
  internationalization: "enUS" | "es" | "ptBR";
  children?: React.ReactNode;
}

export const HowToUse: React.FunctionComponent<IHowToUseProps> = (props) => {
  const showTextsi18n = showTexts(props.internationalization);
  const [width, setWindowWidth] = React.useContext(Dimension);

  const updateDimensions = () => {
    const width = window.outerWidth;
    setWindowWidth(width);
  };

  const getDimensions = React.useCallback(async () => {
    updateDimensions();
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  React.useEffect(() => {
    getDimensions();
  }, [getDimensions]);

  return (
    <HowToUseContainer id="howToUse">
      <HowToUseWrapper>
        <HowToUseContent>
          <HowToUseTitle>
            <Typography
              alignment="left"
              text={showTextsi18n.titleSection}
              fontFamily="Work Sans"
              fontSize={width < 669 ? "7" : "8"}
              fontWeight={600}
              color={color_neutral_1}
            />
          </HowToUseTitle>
          {showTextsi18n.items.map((item, key) => {
            return (
              <Item
                lastItem={showTextsi18n.items.length === key + 1}
                key={key}
                {...item}
              />
            );
          })}
        </HowToUseContent>
        <HowToUseImageSection>
          <Image
            source={showTextsi18n.image}
            width={width < 669 ? "90vw" : "420px"}
          />
        </HowToUseImageSection>
      </HowToUseWrapper>
    </HowToUseContainer>
  );
};

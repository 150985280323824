import React from "react";
import { BoxIcon } from "zera";

import { ItemIconContainer, ItemIconLine } from "./style";

export interface IItemIconProps {
  icon: string;
  showLine?: boolean;
}

export const ItemIcon: React.FunctionComponent<IItemIconProps> = ({
  icon,
  showLine = false,
}) => {
  return (
    <ItemIconContainer>
      <BoxIcon
        variantSize="small"
        type="own-icon"
        size="1.8rem"
        source={icon}
        colorSvgPrimary="#0076DA"
      />
      {showLine && <ItemIconLine />}
    </ItemIconContainer>
  );
};
